import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, DestroyRef, HostListener, PLATFORM_ID, TransferState, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import {
    ActivationEnd,
    NavigationEnd,
    NavigationSkipped,
    NavigationStart,
    ResolveEnd,
    Router,
    RouterOutlet,
    Scroll,
} from '@angular/router';
import { AppInsightsWebModule } from '@e-bilet/app-insights-web';
import { CookiesPolicyModule } from '@e-bilet/cookies-policy';
import { GtmModule } from '@e-bilet/gtm';
import { SyneriseModule } from '@e-bilet/synerise';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiDialogModule } from '@e-bilet/ui-dialog';
import { UiDrawerModule } from '@e-bilet/ui-drawer';
import { UiTicketPanelModule } from '@e-bilet/ui-ticket-panel';
import { UiTopbarsModule } from '@e-bilet/ui-topbars';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { combineLatest } from 'rxjs';
import { endWith, filter, map, takeWhile } from 'rxjs/operators';
import { CookiebotService } from '../../../../libs/cookiebot/src/lib/cookiebot.service';
import { CookieConsent, CookiesPolicyService } from '../../../../libs/cookies-policy/src/lib/cookies-policy.service';
import { CookieName, CookiesService } from '../../../../libs/cookies-policy/src/lib/cookies.service';
import { DeviceService } from '../../../../libs/device/src/lib/device.service';
import { GtmInitializerService } from '../../../../libs/gtm/src/lib/gtm-initializer.service';
import { SyneriseInitializerService } from '../../../../libs/synerise/src/lib/synerise-initializer.service';
import { UOKIK_DATE_KEY } from '../consts/uokik-date-key';
import { environment } from '../environments/environment';
import { AppZorroModule } from './app-zorro.module';
import { PageLoadingSpinnerComponent } from './page-loading-spinner/page-loading-spinner.component';
import { HomeRestService } from './rest-api/home-rest.service';
import { IFrame } from './rest-api/models/iframe';
import { IframeService } from './services/iframe.service';
import { InternationalizationService } from './services/internationalization.service';
import { SsrCacheService } from './services/ssr-cache.service';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';

@Component({
    selector: 'eb-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        CookiesPolicyModule,
        UiDrawerModule,
        UiDialogModule,
        UiTicketPanelModule,
        UiButtonsModule,
        AppZorroModule,
        FormsModule,
        HttpClientModule,
        UiTopbarsModule,
        SharedModule,
        TranslocoRootModule,
        GtmModule,
        SyneriseModule,
        AppInsightsWebModule,
        SvgIconComponent,
        PageLoadingSpinnerComponent,
    ],
})
export class AppComponent {
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);
    private readonly _cookiesService = inject(CookiesService);
    private readonly _cookiebotService = inject(CookiebotService);
    private readonly _gtmInitializerService = inject(GtmInitializerService);
    private readonly _syneriseInitializerService = inject(SyneriseInitializerService);
    private readonly _homeRestService = inject(HomeRestService);
    private readonly _router = inject(Router);
    private readonly _iframeService = inject(IframeService);
    private readonly _deviceService = inject(DeviceService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _transferState = inject(TransferState);
    private readonly _ssrCacheService = inject(SsrCacheService);
    private readonly _i18nService = inject(InternationalizationService);
    private _destroyRef = inject(DestroyRef);

    @HostListener('window:CookiebotOnDialogDisplay', ['$event']) onCookiebotDisplay(e: any) {
        this._homeRestService.cookiesPolicyShowed().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
    }

    @HostListener('window:CookiebotOnAccept', ['$event']) onCookiebotAccept(e: any) {
        this._cookiebotService.onCookiebotChange(true);
    }

    @HostListener('window:CookiebotOnDecline', ['$event']) onCookiebotDecline(e: any) {
        this._cookiebotService.onCookiebotChange(false);
    }

    @HostListener('window:ebSyneriseInited', ['$event']) onSyneriseInited(e: any) {
        this._syneriseInitializerService.inited();
    }

    isPageLoading = signal(false);
    isDialogVisible = false;
    private readonly cookiesDialogWhitelist: string[] = [
        '/lp/polityka-prywatnosci',
        '/lp/privacy-policy',
        '/lp/polityka-plikow-cookies',
        '/lp/cookie-policy',
        '/lp/deklaracja-plikow-cookies',
    ];

    private _routerEvents$ = this._router.events;

    constructor() {
        this._handleTransferState();
        if (isPlatformBrowser(this._platformId)) {
            this._gtmInitializerService.init();
            this._initCookiebot();
            this._initSynerise();
            this._initIframeCookies();

            this._routerEvents$.subscribe((evt: any) => {
                if (evt instanceof NavigationEnd) {
                    this._ssrCacheService.setCacheEnabled(evt.url); // wyłączenie ssr cache interceptora po przejściu na kolejną podstronę
                }

                if (
                    // usunięcie blura ze strony zaczepione na wiele eventów, by zminimalizować ryzyko nieusunięcia blura po zakończonej nawigacji
                    evt instanceof Scroll ||
                    evt instanceof NavigationEnd ||
                    evt instanceof NavigationSkipped ||
                    evt instanceof ActivationEnd ||
                    evt instanceof ResolveEnd
                ) {
                    this.isPageLoading.set(false);
                } else if (evt instanceof NavigationStart && !this._i18nService.changingLanguage) {
                    this.isPageLoading.set(true);
                }
            });
        } else {
            this._iframeService.iframe$.pipe(takeUntilDestroyed()).subscribe((iframe: IFrame | undefined) => {
                if (!iframe) {
                    this._syneriseInitializerService.addSyneriseScript(environment.syneriseTrackerKey, true);
                }
            });
        }
    }

    private _initCookiebot(): void {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        const isCookiebotHiddenPage$ = this._routerEvents$.pipe(
            filter((evt) => evt instanceof NavigationEnd),
            map((evt) => this.cookiesDialogWhitelist.includes((evt as NavigationEnd)?.url.replace(/^\/en(\/)?/, '/'))),
        );

        combineLatest([this._iframeService.iframe$, isCookiebotHiddenPage$, CookiebotService.inited$])
            .pipe(
                takeWhile(
                    ([isIframe, isCookiebotHiddenPage, isCookiebotInited]: [IFrame | undefined, boolean, boolean]) =>
                        !isIframe && !isCookiebotInited,
                ),
            )
            .subscribe(
                ([isIframe, isCookiebotHiddenPage, isCookiebotInited]: [IFrame | undefined, boolean, boolean]) => {
                    if (!isIframe && !isCookiebotInited) {
                        const isCookiebotDecided = this._cookiesService.getCookieValue(CookieName.CookieAgree);

                        if (isCookiebotHiddenPage && !isCookiebotDecided) {
                            (window as any).Cookiebot = { renew: () => this._cookiebotService.initCookiebot() };
                        } else {
                            this._cookiebotService.initCookiebot();
                        }
                    }
                },
            );
    }

    private _initSynerise(): void {
        this._cookiesPolicyService.cookiesPolicyConsent$
            .pipe(takeUntilDestroyed())
            .subscribe((cookiesPolicyConsent: CookieConsent | undefined) => {
                if (cookiesPolicyConsent?.marketing) {
                    this._syneriseInitializerService.init(environment.syneriseTrackerKey);
                }
            });
    }

    private _initIframeCookies(): void {
        // weryfikacja zgód cookies w iframe
        const isIframe$ = this._iframeService.iframe$.pipe(
            takeUntilDestroyed(),
            map((iframe: IFrame | undefined) => !!iframe),
        );

        isIframe$.subscribe((isIframe: boolean) => {
            if (isIframe) {
                this._cookiesPolicyService.verifyCookiesConsentIframe();
            }
        });

        const isCookiesPolicyConsentDecided$ = this._cookiesPolicyService.cookiesPolicyConsent$.pipe(
            map((cookiesPolicyConsent: CookieConsent | undefined) => !!cookiesPolicyConsent),
        );

        const isIframeNoIosAndSafari$ = isIframe$.pipe(
            map((isIframe: boolean) => isIframe && !this._deviceService.checkIfUserAgentIsIosOrSafari()),
        );

        combineLatest([isCookiesPolicyConsentDecided$, isIframeNoIosAndSafari$])
            .pipe(
                map(
                    ([isCookiesPolicyConsentDecided$, isIframeNoIosAndSafari$]: [boolean, boolean]) =>
                        isIframeNoIosAndSafari$ && !isCookiesPolicyConsentDecided$,
                ),
                endWith(false),
                takeUntilDestroyed(),
            )
            .subscribe((isDialogVisible) => {
                this.isDialogVisible = isDialogVisible;
            });
    }

    private _handleTransferState(): void {
        if (isPlatformServer(this._platformId)) {
            this._transferState.set(UOKIK_DATE_KEY, this._checkUokikAnnouncement());
        }
    }

    private _checkUokikAnnouncement(): boolean {
        const startDate = new Date('December 29, 2023 22:00:00');
        const expiryDate = new Date('March 1, 2024 00:00:00');
        const today = new Date().getTime();
        return startDate.getTime() < today && today < expiryDate.getTime();
    }
}
