import { addTicketIcon } from './shared/icons/add-ticket';
import { arrowDownIcon } from './shared/icons/arrow-down';
import { arrowLeftIcon } from './shared/icons/arrow-left';
import { arrowUpIcon } from './shared/icons/arrow-up';
import { balloonsIcon } from './shared/icons/balloons';
import { balloons48Icon } from './shared/icons/balloons_48';
import { bellIcon } from './shared/icons/bell';
import { bellYellowIcon } from './shared/icons/bell-yellow';
import { brandAllegroIcon } from './shared/icons/brand-allegro';
import { brandAppleIcon } from './shared/icons/brand-apple';
import { brandFacebookIcon } from './shared/icons/brand-facebook';
import { brandGoogleIcon } from './shared/icons/brand-google';
import { brandInstagramIcon } from './shared/icons/brand-instagram';
import { brandLinkedinIcon } from './shared/icons/brand-linkedin';
import { brandSpotifyIcon } from './shared/icons/brand-spotify';
import { brandTiktokIcon } from './shared/icons/brand-tiktok';
import { brandTwitterIcon } from './shared/icons/brand-twitter';
import { brandYoutubeIcon } from './shared/icons/brand-youtube';
import { briefcaseIcon } from './shared/icons/briefcase';
import { briefcase48Icon } from './shared/icons/briefcase_48';
import { calendarIcon } from './shared/icons/calendar';
import { calendarMonthIcon } from './shared/icons/calendar-month';
import { calendarMonthTiltIcon } from './shared/icons/calendar-month-tilt';
import { cameraIcon } from './shared/icons/camera';
import { camera48Icon } from './shared/icons/camera_48';
import { cashIcon } from './shared/icons/cash';
import { cash48Icon } from './shared/icons/cash_48';
import { checkIcon } from './shared/icons/check';
import { chevronDownIcon } from './shared/icons/chevron-down';
import { chevronLeftIcon } from './shared/icons/chevron-left';
import { chevronRightIcon } from './shared/icons/chevron-right';
import { chevronUpIcon } from './shared/icons/chevron-up';
import { circusIcon } from './shared/icons/circus';
import { circus48Icon } from './shared/icons/circus_48';
import { closeIcon } from './shared/icons/close';
import { collaborationIcon } from './shared/icons/collaboration';
import { copyIcon } from './shared/icons/copy';
import { crosshairIcon } from './shared/icons/crosshair';
import { cupIcon } from './shared/icons/cup';
import { cup48Icon } from './shared/icons/cup_48';
import { dotsIcon } from './shared/icons/dots';
import { doubleHeartIcon } from './shared/icons/double-heart';
import { doubleHeart48Icon } from './shared/icons/double-heart_48';
import { downloadIcon } from './shared/icons/download';
import { envelopeIcon } from './shared/icons/envelope';
import { eyeIcon } from './shared/icons/eye';
import { eyeClosedIcon } from './shared/icons/eye-closed';
import { filtersIcon } from './shared/icons/filters';
import { filters2Icon } from './shared/icons/filters-2';
import { fullscreenIcon } from './shared/icons/fullscreen';
import { fullscreenOffIcon } from './shared/icons/fullscreen-off';
import { galleryIcon } from './shared/icons/gallery';
import { gridIcon } from './shared/icons/grid';
import { guitarIcon } from './shared/icons/guitar';
import { guitar48Icon } from './shared/icons/guitar_48';
import { heartIcon } from './shared/icons/heart';
import { heartRedIcon } from './shared/icons/heart-red';
import { imageIcon } from './shared/icons/image';
import { image48Icon } from './shared/icons/image_48';
import { infoIcon } from './shared/icons/info';
import { linkIcon } from './shared/icons/link';
import { listIcon } from './shared/icons/list';
import { loadingIcon } from './shared/icons/loading';
import { locationPinIcon } from './shared/icons/location-pin';
import { maskIcon } from './shared/icons/mask';
import { mask48Icon } from './shared/icons/mask_48';
import { microphoneIcon } from './shared/icons/microphone';
import { microphone48Icon } from './shared/icons/microphone_48';
import { minusIcon } from './shared/icons/minus';
import { musicalNotesIcon } from './shared/icons/musical-notes';
import { musicalNotes48Icon } from './shared/icons/musical-notes_48';
import { payByInstalmentsIcon } from './shared/icons/pay-by-instalments';
import { paypoIcon } from './shared/icons/paypo';
import { playIcon } from './shared/icons/play';
import { plusIcon } from './shared/icons/plus';
import { resetIcon } from './shared/icons/reset';
import { searchIcon } from './shared/icons/search';
import { securityIcon } from './shared/icons/security';
import { security48Icon } from './shared/icons/security_48';
import { shareIcon } from './shared/icons/share';
import { shoppingCartIcon } from './shared/icons/shopping-cart';
import { shoppingCartFullIcon } from './shared/icons/shopping-cart-full';
import { starIcon } from './shared/icons/star';
import { star48Icon } from './shared/icons/star_48';
import { successIcon } from './shared/icons/success';
import { teddyBearIcon } from './shared/icons/teddy-bear';
import { teddyBear48Icon } from './shared/icons/teddy-bear_48';
import { thumbDownIcon } from './shared/icons/thumb-down';
import { thumbUpIcon } from './shared/icons/thumb-up';
import { ticketIcon } from './shared/icons/ticket';
import { ticketErrorTiltIcon } from './shared/icons/ticket-error-tilt';
import { ticketFavIcon } from './shared/icons/ticket-fav';
import { ticketFavTiltIcon } from './shared/icons/ticket-fav-tilt';
import { ticketFav64Icon } from './shared/icons/ticket-fav_64';
import { ticketProgressTiltIcon } from './shared/icons/ticket-progress-tilt';
import { ticketSuccessTiltIcon } from './shared/icons/ticket-success-tilt';
import { ticket48Icon } from './shared/icons/ticket_48';
import { timeIcon } from './shared/icons/time';
import { treeIcon } from './shared/icons/tree';
import { tree48Icon } from './shared/icons/tree_48';
import { uploadIcon } from './shared/icons/upload';
import { userIcon } from './shared/icons/user';
import { waitIcon } from './shared/icons/wait';
import { warningIcon } from './shared/icons/warning';
import { zoomInIcon } from './shared/icons/zoom-in';
import { zoomOutIcon } from './shared/icons/zoom-out';
import { mdnPlayerIcon } from './shared/icons/mdn-player';
import { dealZoneIcon } from './shared/icons/deal-zone';

export const appIcons = [
    addTicketIcon,
    arrowDownIcon,
    arrowLeftIcon,
    arrowUpIcon,
    balloons48Icon,
    balloonsIcon,
    bellYellowIcon,
    bellIcon,
    brandAllegroIcon,
    brandAppleIcon,
    brandFacebookIcon,
    brandGoogleIcon,
    brandInstagramIcon,
    brandLinkedinIcon,
    brandSpotifyIcon,
    brandTiktokIcon,
    brandTwitterIcon,
    brandYoutubeIcon,
    briefcase48Icon,
    briefcaseIcon,
    calendarMonthTiltIcon,
    calendarMonthIcon,
    calendarIcon,
    camera48Icon,
    cameraIcon,
    cash48Icon,
    cashIcon,
    checkIcon,
    chevronDownIcon,
    chevronLeftIcon,
    chevronRightIcon,
    chevronUpIcon,
    circus48Icon,
    circusIcon,
    closeIcon,
    collaborationIcon,
    copyIcon,
    crosshairIcon,
    cup48Icon,
    cupIcon,
    dealZoneIcon,
    dotsIcon,
    doubleHeart48Icon,
    doubleHeartIcon,
    downloadIcon,
    envelopeIcon,
    eyeClosedIcon,
    eyeIcon,
    filters2Icon,
    filtersIcon,
    fullscreenOffIcon,
    fullscreenIcon,
    galleryIcon,
    gridIcon,
    guitar48Icon,
    guitarIcon,
    heartRedIcon,
    heartIcon,
    image48Icon,
    imageIcon,
    infoIcon,
    linkIcon,
    listIcon,
    loadingIcon,
    locationPinIcon,
    mask48Icon,
    maskIcon,
    microphone48Icon,
    microphoneIcon,
    mdnPlayerIcon,
    minusIcon,
    musicalNotes48Icon,
    musicalNotesIcon,
    payByInstalmentsIcon,
    playIcon,
    plusIcon,
    paypoIcon,
    resetIcon,
    searchIcon,
    security48Icon,
    securityIcon,
    shareIcon,
    shoppingCartFullIcon,
    shoppingCartIcon,
    star48Icon,
    starIcon,
    successIcon,
    teddyBear48Icon,
    teddyBearIcon,
    thumbDownIcon,
    thumbUpIcon,
    ticket48Icon,
    ticketErrorTiltIcon,
    ticketFav64Icon,
    ticketFavTiltIcon,
    ticketFavIcon,
    ticketProgressTiltIcon,
    ticketSuccessTiltIcon,
    ticketIcon,
    timeIcon,
    tree48Icon,
    treeIcon,
    uploadIcon,
    userIcon,
    waitIcon,
    warningIcon,
    zoomInIcon,
    zoomOutIcon,
];
